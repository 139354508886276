import DashboardIcon from "@rsuite/icons/Dashboard";
import EmailFillIcon from "@rsuite/icons/EmailFill";
import GearIcon from "@rsuite/icons/Gear";
import MemberIcon from "@rsuite/icons/Member";
import SendIcon from "@rsuite/icons/Send";
import GroupIcon from "@rsuite/icons/legacy/Group";
import React, { useEffect, useState } from "react";
import { BiSolidUserCircle } from "react-icons/bi";
import { Link, Route, Routes } from "react-router-dom";
import { useRecoilState } from "recoil";
import PhoneIcon from "@rsuite/icons/Phone";
import {
  Container,
  Content,
  Dropdown,
  Header,
  Nav,
  Sidebar,
  Sidenav,
} from "rsuite";
import { isLogged } from "./Atoms/auth.atom";
import Login from "./Screens/Auth/login";
import Customer from "./Screens/Customer";
import OldPelerent from "./Screens/OldCustomer";

import "./App.scss";
import Dashboard from "./Screens/Dashboard";
import Guide from "./Screens/Guide";
import Messages from "./Screens/Messages";
import SendMessages from "./Screens/SendMessages";
import VerifyCoupon from "./Screens/verifyCoupon";
import CheckRoundIcon from "@rsuite/icons/CheckRound";
import ExportExcel from "./Screens/ExportFile/ExportExcel";
import SurveyVo from "./Screens/SurveyVo";
import Tickets from "./Screens/Ticket";
import Hotels from "./Screens/Hotels";
import SurveyMadinah from "./Screens/SurveyMadinah";
import SurveyMakkah from "./Screens/SurveyMakkah";
import Users from "./Screens/Users";
import { userCredentials } from "./Atoms/userCredentials.atom";

function App() {
  const [expand, setExpand] = useState(true);
  const [logged, setlogged] = useRecoilState(isLogged);
  const [active, setactive] = useState("1");
  const [pageTitle, setpageTitle] = useState("Dashboard");
  const [userCredential, setuserCredential] = useRecoilState(userCredentials);

  /////--------

  ////////////////////////
  const AuthGard = (Screen) => (logged ? Screen : <Login />);
  useEffect(() => {
    let log = true;
    if (
      !localStorage.getItem("auth") ||
      !JSON.parse(localStorage.getItem("auth")).token
    ) {
      log = false;
    } else {
      setlogged(log);
      if (
        window.location.pathname == "/" ||
        window.location.pathname.substring(1) == "Dashboard"
      ) {
        setpageTitle("Dashboard");
        setactive(1);
      } else if (window.location.pathname.substring(1) == "Add_Customer") {
        setpageTitle("Ajouter Mootmar");
        setactive(2);
      } else if (window.location.pathname.substring(1) == "Customer") {
        setpageTitle(" Sondage Umrah");
        setactive(3);
      } else if (window.location.pathname.substring(1) == "SurveyMadinah") {
        setpageTitle(" SurveyMadinah");
        setactive(88);
      } else if (window.location.pathname.substring(1) == "SurveyMakkah") {
        setpageTitle(" SurveyMakkah");
        setactive(888);
      } else if (window.location.pathname.substring(1) == "SendMessages") {
        setpageTitle("Envoyer Messages");
        setactive(5);
      }
      if (window.location.pathname.substring(1) == "Guide") {
        setpageTitle("Guide");
        setactive(6);
      } else if (window.location.pathname.substring(1) == "Messages") {
        setpageTitle("Messages");
        setactive(4);
      }
    }
  }, [logged]);
  // useEffect(() => getGuides(), []);
  useEffect(() => console.log(userCredential), [userCredential]);

  return (
    <div className="show-fake-browser sidebar-page">
      <Container>
        {logged && (
          <Sidebar
            style={{ display: "flex", flexDirection: "column" }}
            width={expand ? 260 : 56}
            collapsible
          >
            <Sidenav.Header>
              <div style={headerStyles}>
                <img
                  src="./logo.png"
                  height={40}
                  width={40}
                  style={{ marginLeft: 10 }}
                />
                <span style={{ fontSize: 21 }}>Kounouz Travel CRM</span>
              </div>
            </Sidenav.Header>
            <Sidenav
              expanded={expand}
              defaultOpenKeys={["1"]}
              appearance="subtle"
            >
              <Sidenav.Body>
                <Nav>
                  <Nav.Item
                    eventKey="1"
                    active={active == "1"}
                    icon={<DashboardIcon />}
                    style={{ borderBottom: "solid 1px" }}
                    onClick={() => {
                      setpageTitle("Dashboard");
                      setactive(1);
                    }}
                  >
                    <Link style={active != 1 ? { color: "black" } : {}} to="/">
                      Dashboard
                    </Link>
                  </Nav.Item>

                  {/* <Nav.Item
                    eventKey="2"
                    active={active == "2"}
                    icon={<GroupIcon />}
                    onClick={() => {
                      setpageTitle("Ajouter Mootmar ");
                      setactive(2);
                    }}
                  >
                    <Link
                      style={active != 2 ? { color: "black" } : {}}
                      to="/Add_Customer"
                    >
                      Ajouter Mootmar
                    </Link>
                  </Nav.Item> */}
                  {(JSON.parse(localStorage.getItem("auth")).role ==
                    "CentralUser" ||
                    JSON.parse(localStorage.getItem("auth")).role ==
                      "AdminCrm" ||
                    JSON.parse(localStorage.getItem("auth")).fullName ==
                      "Admin Super") && (
                    <Nav.Item
                      eventKey="3"
                      active={active == "3"}
                      style={{ borderBottom: "solid 1px" }}
                      icon={<PhoneIcon />}
                      onClick={() => {
                        setpageTitle(" Sondage Umrah ");
                        setactive(3);
                      }}
                    >
                      <Link
                        style={active != 3 ? { color: "black" } : {}}
                        to="/Customer"
                      >
                        Sondage Umrah
                      </Link>
                    </Nav.Item>
                  )}
                  {(JSON.parse(localStorage.getItem("auth")).role ==
                    "MadinahUser" ||
                    JSON.parse(localStorage.getItem("auth")).role ==
                      "AdminCrm" ||
                    JSON.parse(localStorage.getItem("auth")).fullName ==
                      "Admin Super") && (
                    <Nav.Item
                      eventKey="88"
                      active={active == "88"}
                      icon={<PhoneIcon />}
                      style={{ borderBottom: "solid 1px" }}
                      onClick={() => {
                        setpageTitle(" Sondage Madinah ");
                        setactive(88);
                      }}
                    >
                      <Link
                        style={active != 88 ? { color: "black" } : {}}
                        to="/SurveyMadinah"
                      >
                        Sondage Madinah
                      </Link>
                    </Nav.Item>
                  )}
                  {(JSON.parse(localStorage.getItem("auth")).role ==
                    "MakkahUser" ||
                    JSON.parse(localStorage.getItem("auth")).role ==
                      "AdminCrm" ||
                    JSON.parse(localStorage.getItem("auth")).fullName ==
                      "Admin Super") && (
                    <Nav.Item
                      eventKey="888"
                      active={active == "888"}
                      style={{
                        borderBottom: "solid 1px",
                      }}
                      icon={<PhoneIcon />}
                      onClick={() => {
                        setpageTitle(" Sondage Makkah ");
                        setactive(888);
                      }}
                    >
                      <Link
                        style={active != 888 ? { color: "black" } : {}}
                        to="/SurveyMakkah"
                      >
                        Sondage Makkah
                      </Link>
                    </Nav.Item>
                  )}

                  {/* <Nav.Item
                    eventKey="14"
                    active={active == "14"}
                    icon={<PhoneIcon />}
                    onClick={() => {
                      setpageTitle("Sondage Voyage Organisé");
                      setactive(14);
                    }}
                  >
                    <Link
                      style={active != 14 ? { color: "black" } : {}}
                      to="/SurveyVO"
                    >
                      Sondage V.O
                    </Link>
                  </Nav.Item> */}
                  {/* <Nav.Item
                    eventKey="15"
                    active={active == "15"}
                    icon={<PhoneIcon />}
                    onClick={() => {
                      setpageTitle("Tickets");
                      setactive(15);
                    }}
                  >
                    <Link
                      style={active != 15 ? { color: "black" } : {}}
                      to="/Tickets"
                    >
                      Billeterie
                    </Link>
                  </Nav.Item>
                  <Nav.Item
                    eventKey="16"
                    active={active == "16"}
                    icon={<PhoneIcon />}
                    onClick={() => {
                      setpageTitle("Hotels ");
                      setactive(16);
                    }}
                  >
                    <Link
                      style={active != 16 ? { color: "black" } : {}}
                      to="/Hotels"
                    >
                      Hôtels
                    </Link>
                  </Nav.Item> */}

                  <Nav.Item
                    eventKey="6"
                    active={active == "6"}
                    style={{ borderBottom: "solid 1px" }}
                    icon={<MemberIcon />}
                    onClick={() => {
                      setpageTitle("Guide");
                      setactive(6);
                    }}
                  >
                    <Link
                      style={active != 6 ? { color: "black" } : {}}
                      to="/Guide"
                    >
                      Guide
                    </Link>
                  </Nav.Item>
                  {JSON.parse(localStorage.getItem("auth")) &&
                    (JSON.parse(localStorage.getItem("auth")).role ==
                      "CentralUser" ||
                      JSON.parse(localStorage.getItem("auth")).role ==
                        "AdminCrm" ||
                      JSON.parse(localStorage.getItem("auth")).fullName ==
                        "Admin Super") && (
                      <Nav.Item
                        eventKey="4"
                        active={active == "4"}
                        style={{ borderBottom: "solid 1px" }}
                        icon={<EmailFillIcon />}
                        onClick={() => {
                          setpageTitle("Messages");
                          setactive(4);
                        }}
                      >
                        <Link
                          style={active != 4 ? { color: "black" } : {}}
                          to="/Messages"
                        >
                          Messages
                        </Link>
                      </Nav.Item>
                    )}
                  {(JSON.parse(localStorage.getItem("auth")).role ==
                    "CentralUser" ||
                    JSON.parse(localStorage.getItem("auth")).role ==
                      "AdminCrm" ||
                    JSON.parse(localStorage.getItem("auth")).fullName ==
                      "Admin Super") && (
                    <Nav.Item
                      eventKey="5"
                      active={active == "5"}
                      style={{ borderBottom: "solid 1px" }}
                      icon={<SendIcon />}
                      onClick={() => {
                        setpageTitle("Envoyer Messages");
                        setactive(5);
                      }}
                    >
                      <Link
                        style={active != 5 ? { color: "black" } : {}}
                        to="/SendMessages"
                      >
                        Envoyer Messages
                      </Link>
                    </Nav.Item>
                  )}
                  {(JSON.parse(localStorage.getItem("auth")).role ==
                    "AdminCrm" ||
                    JSON.parse(localStorage.getItem("auth")).fullName ==
                      "Admin Super") && (
                    <Nav.Item
                      eventKey="666"
                      active={active == "666"}
                      style={{ borderBottom: "solid 1px" }}
                      icon={<MemberIcon />}
                      onClick={() => {
                        setpageTitle("Utilisateurs");
                        setactive(666);
                      }}
                    >
                      <Link
                        style={active != 666 ? { color: "black" } : {}}
                        to="/Users"
                      >
                        Utilisateurs
                      </Link>
                    </Nav.Item>
                  )}
                </Nav>
              </Sidenav.Body>
            </Sidenav>
          </Sidebar>
        )}

        <Container style={{ minHeight: "100vh" }}>
          {logged && (
            <Header
              style={{
                height: 56,
                marginLeft: 15,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h2> {pageTitle} </h2>
              <div
                style={{
                  display: "inline-flex",
                  marginRight: 15,
                }}
              >
                {/* <button
                  className="menu_btn"
                  onClick={(e) => setExpand((prev) => !prev)}
                >
                  <BsMenuButton />
                </button> */}
                <Nav>
                  <Dropdown
                    placement="bottomEnd"
                    trigger="click"
                    icon={<GearIcon size="3em" />}
                    // renderTitle={(children) => {
                    //   return <GearIcon style={iconStyles} />;
                    // }}
                  >
                    <Dropdown.Item>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div
                          style={{
                            width: 20,
                            height: 20,
                            // background: "red",
                            borderRadius: "50%",
                            marginRight: 5,
                          }}
                        >
                          <BiSolidUserCircle size={20} />
                        </div>
                        <span>
                          {JSON.parse(localStorage.getItem("auth")).fullName}
                        </span>
                      </div>
                    </Dropdown.Item>
                    {/* <Dropdown.Item>Help</Dropdown.Item>
                    <Dropdown.Item>Settings</Dropdown.Item> */}
                    <Dropdown.Item
                      onClick={() => {
                        localStorage.removeItem("auth");
                        setlogged(false);
                      }}
                      style={{ textAlign: "center" }}
                    >
                      Sign out
                    </Dropdown.Item>
                  </Dropdown>
                </Nav>
              </div>
            </Header>
          )}
          <Content style={{ background: "rgb(238,238,238)" }}>
            {/* <Button appearance="primary" onClick={() => FixGuide()}>
              Test
            </Button> */}
            <Routes>
              <Route path="*" element={AuthGard(<Dashboard />)} />
              <Route path="/" element={AuthGard(<Dashboard />)} />
              {/* <Route path="/Add_Customer" element={AuthGard(<OldPelerent />)} /> */}
              {JSON.parse(localStorage.getItem("auth")) &&
                (JSON.parse(localStorage.getItem("auth")).role ==
                  "CentralUser" ||
                  JSON.parse(localStorage.getItem("auth")).role == "AdminCrm" ||
                  JSON.parse(localStorage.getItem("auth")).fullName ==
                    "Admin Super") && (
                  <Route path="/Customer" element={AuthGard(<Customer />)} />
                )}
              {JSON.parse(localStorage.getItem("auth")) &&
                (JSON.parse(localStorage.getItem("auth")).role ==
                  "MadinahUser" ||
                  JSON.parse(localStorage.getItem("auth")).role == "AdminCrm" ||
                  JSON.parse(localStorage.getItem("auth")).fullName ==
                    "Admin Super") && (
                  <Route
                    path="/SurveyMadinah"
                    element={AuthGard(<SurveyMadinah />)}
                  />
                )}
              {JSON.parse(localStorage.getItem("auth")) &&
                (JSON.parse(localStorage.getItem("auth")).role ==
                  "MakkahUser" ||
                  JSON.parse(localStorage.getItem("auth")).role == "AdminCrm" ||
                  JSON.parse(localStorage.getItem("auth")).fullName ==
                    "Admin Super") && (
                  <Route
                    path="/SurveyMakkah"
                    element={AuthGard(<SurveyMakkah />)}
                  />
                )}

              {/* <Route path="/SurveyVO" element={AuthGard(<SurveyVo />)} />
              <Route path="/Tickets" element={AuthGard(<Tickets />)} />
              <Route path="/Hotels" element={AuthGard(<Hotels />)} /> */}
              <Route path="/Guide" element={AuthGard(<Guide />)} />
              {JSON.parse(localStorage.getItem("auth")) &&
                (JSON.parse(localStorage.getItem("auth")).role ==
                  "CentralUser" ||
                  JSON.parse(localStorage.getItem("auth")).role == "AdminCrm" ||
                  JSON.parse(localStorage.getItem("auth")).fullName ==
                    "Admin Super") && (
                  <Route
                    path="/SendMessages"
                    element={AuthGard(<SendMessages />)}
                  />
                )}
              {JSON.parse(localStorage.getItem("auth")) &&
                (JSON.parse(localStorage.getItem("auth")).role ==
                  "CentralUser" ||
                  JSON.parse(localStorage.getItem("auth")).role == "AdminCrm" ||
                  JSON.parse(localStorage.getItem("auth")).fullName ==
                    "Admin Super") && (
                  <Route path="/Messages" element={AuthGard(<Messages />)} />
                )}
              {/* <Route
                path="/verifyCoupon"
                element={AuthGard(<VerifyCoupon />)}
              /> */}
              {JSON.parse(localStorage.getItem("auth")) &&
                (JSON.parse(localStorage.getItem("auth")).role == "AdminCrm" ||
                  JSON.parse(localStorage.getItem("auth")).fullName ==
                    "Admin Super") && (
                  <Route path="/Users" element={AuthGard(<Users />)} />
                )}
            </Routes>
          </Content>
        </Container>
      </Container>
    </div>
  );
}

export default App;

const headerStyles = {
  display: "flex",
  alignItems: "center",
  padding: 5,
  fontSize: 16,
  height: 56,
  background: "rgb(77,76,172)",
  color: " #fff",
  whiteSpace: "nowrap",
  overflow: "hidden",
};
