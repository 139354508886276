import React from "react";
import { Button, IconButton, Input, Loader, Modal } from "rsuite";
import ChangeListIcon from "@rsuite/icons/ChangeList";
import PlusRoundIcon from "@rsuite/icons/PlusRound";
import Divider from "rsuite/Divider";
import { exportAddAtom } from "../../../Atoms/exportAdd.atom";
import { useRecoilState } from "recoil";
import ExportExcel from "./excelExport";
import { surveyStateAtom } from "../../../Atoms/surveyState.atom";
import AddOutlineIcon from "@rsuite/icons/AddOutline";
import { createAPIEndpoint } from "../../../API/authenticated.request";
import FileDownloadIcon from "@rsuite/icons/FileDownload";
import { PDFDownloadLink } from "@react-pdf/renderer";
import PdftoPrint from "../../../Screens/Customer/PdftoPrint";

export default function ExportAdd({
  title,
  button,
  AddComponent,
  excelData,
  nameExcel,
  noExport,
  noimportExcel,
  save,
  noAdd,
  ActionOnClose,
  size,
  noServey,
  surveyButton,
  AddsurveyComponent,
  saveSurvey,
  uploadExcel,
  pdfDownload,
  pdfComponent,
  pdfTitle,
}) {
  const [surveyState, setsurveyState] = useRecoilState(surveyStateAtom);

  const handleOpen = () =>
    setstate((prev) => {
      return { ...prev, open: true };
    });
  const handleSurveyOpen = () =>
    setsurveyState((prev) => {
      return { ...prev, open: true };
    });
  const [state, setstate] = useRecoilState(exportAddAtom);
  const handleClose = () =>
    setstate((prev) => {
      return { ...prev, open: false };
    });
  const handleSurveyClose = () =>
    setsurveyState((prev) => {
      return { ...prev, open: false };
    });
  const exportExcel = () => {
    document.querySelector("#hidden-btn-export").click();
  };
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        padding: "15px 0",
        zIndex: "1",
        maxWidth: "100vw",
      }}
    >
      {pdfDownload && (
        <PDFDownloadLink
          document={pdfComponent}
          fileName={pdfTitle ? pdfTitle : "Document"}
        >
          <IconButton
            appearance="primary"
            color="red"
            icon={<FileDownloadIcon />}
          >
            DowloadPdf
          </IconButton>
        </PDFDownloadLink>
      )}
      {!noimportExcel && (
        <IconButton appearance="primary" color="green" icon={<PlusRoundIcon />}>
          Ajouter Excel
          <div
            style={{
              position: "absolute",
              top: "0%",
              right: "0%",
              opacity: 0,
              height: "100%",
              width: "100%",
            }}
          >
            <input
              type="file"
              onChange={(v) => {
                uploadExcel(v.target.files[0]);
              }}
            />
          </div>
        </IconButton>
      )}
      {!noExport && (
        <Button color="green" onClick={exportExcel} appearance="primary">
          <ChangeListIcon /> Export
        </Button>
      )}
      <Divider vertical />
      {!noAdd && (
        <>
          {button && (
            <IconButton
              onClick={handleOpen}
              appearance="primary"
              icon={<PlusRoundIcon />}
            >
              Ajout
            </IconButton>
          )}

          <Modal
            size={size ? size : "calc(100% - 120px)"}
            overflow={false}
            style={{
              maxHeight: "calc(100vh - 50px)",
              overflow: "auto",
              maxWidth: "100vw",
            }}
            open={state.open}
            onClose={() => {
              handleClose();
              if (ActionOnClose) ActionOnClose();
            }}
          >
            <Modal.Header style={{}}>
              <Modal.Title
                style={{
                  display: "flex",
                  padding: 4,
                  justifyContent: "right",
                  fontSize: 20,
                }}
              >
                {!title ? "معلومات شخصية" : title}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div
                style={{
                  maxHeight: "calc(100vh - 240px)",
                  overflow: "auto",
                  maxWidth: "100vw",
                }}
              >
                {AddComponent}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={save} appearance="primary">
                {state.loading ? <Loader size="sm" /> : "Enregistrer"}
              </Button>
              <Button
                onClick={() => {
                  handleClose();
                  if (ActionOnClose) ActionOnClose();
                }}
                appearance="subtle"
              >
                Annuler
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      )}
      <Divider vertical />
      {!noServey && (
        <>
          {surveyButton && (
            <IconButton
              onClick={handleSurveyOpen}
              appearance="primary"
              icon={<PlusRoundIcon />}
            >
              Ajout Survey
            </IconButton>
          )}

          <Modal
            size={size ? size : "calc(100% - 120px)"}
            overflow={false}
            style={{
              // maxHeight: "calc(100vh - 50px)",
              overflow: "auto",
              // maxWidth: "100vw",
            }}
            open={surveyState.open}
            onClose={() => {
              handleSurveyClose();
              if (ActionOnClose) ActionOnClose();
            }}
          >
            <Modal.Header style={{}}>
              <Modal.Title
                style={{
                  display: "flex",
                  padding: 4,
                  justifyContent: "right",
                  fontSize: 25,
                }}
              >
                آراء المعتمرين
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div
                style={{
                  maxHeight: "calc(100vh - 240px)",
                  overflow: "auto",
                  maxWidth: "100vw",
                }}
              >
                {AddsurveyComponent}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={saveSurvey} appearance="primary">
                {surveyState.loading ? <Loader size="sm" /> : "Enregistrer"}
              </Button>
              <Button
                onClick={() => {
                  handleSurveyClose();
                  if (ActionOnClose) ActionOnClose();
                }}
                appearance="subtle"
              >
                Annuler
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      )}
      <ExportExcel data={excelData} name={nameExcel} />
    </div>
  );
}
