import {
  Document,
  Font,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import moment from "moment/moment";
import React, { useEffect, useState } from "react";

Font.register({
  family: "NotoArabic",
  src: "/Cairo-VariableFont_slnt,wght.ttf",
});
const styles = StyleSheet.create({
  titleContainer: {
    display: "flex",
    alignItems: "center",
    // backgroundColor: "red",
    margin: 10,
    padding: 15,
    width: "100%",
  },
  text: {
    fontSize: 14,
    fontFamily: "NotoArabic", // Use the registered Arabic font
    textAlign: "right", // Align text to the right
    // lineHeight: 1.6, // Add spacing for readability
  },
  title: {
    fontWeight: "bold",
    fontSize: 25,
    textAlign: "center",
  },
  page: {
    backgroundColor: "#E4E4E4",
  },

  section: {
    margin: 10,
    flexDirection: "row",
    display: "flex",
    justifyContent: "space-between",
    // gap: "5",
    padding: 10,
    flexGrow: 1,
  },
  noteSection: {
    margin: 10,
    padding: 10,

    backgroundColor: "white",
  },
});

const PdftoPrint = ({ customers }) => {
  const [datatoprint, setdatatoprint] = useState([]);
  useEffect(() => {
    setdatatoprint(customers);
  }, [customers]);
  useEffect(() => console.log(datatoprint), [datatoprint]);
  return (
    customers && (
      <div>
        <Page size="A4" wrap={false} style={styles.page}>
          <View style={styles.titleContainer}>
            <Text style={styles.title}>Notes</Text>
            {datatoprint.length != 0 ? (
              <Text style={{ marginTop: 10 }}>
                Date:
                {moment(datatoprint[0].disponibility.date).format("DD/MM/YYYY")}
              </Text>
            ) : (
              ""
            )}
          </View>
          {/* <View>
          <Text>Agence:{filterModel.disponibilityId?datatoprint[0]} </Text>
        </View> */}
          {datatoprint.map((customer, index) => {
            return (
              <View>
                <View style={styles.section}>
                  <Text>Name: {customer.customer.name}</Text>
                  <Text> Téléphone: {customer.customer.phoneNumber} </Text>
                </View>
                <View style={styles.noteSection}>
                  <Text style={styles.text}>
                    {customer.customer.survey &&
                    customer.customer.survey.note != null
                      ? customer.customer.survey.note
                      : ""}
                  </Text>
                </View>
              </View>
            );
          })}
        </Page>
      </div>
    )
  );
};

export default PdftoPrint;
